/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Accordion',
        import: () => import('@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountDashboard',
        import: () => import('@stories/Widgets/AccountDashboard/AccountDashboard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountLogin',
        import: () => import('@stories/Widgets/AccountLogin/AccountLogin'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountNavigation',
        import: () => import('@stories/Widgets/AccountNavigation/AccountNavigation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountRegistration',
        import: () => import('@stories/Widgets/AccountRegistration/AccountRegistration'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AddressBook',
        import: () => import('@stories/Widgets/AddressBook/AddressBook'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AddSiteAddress',
        import: () => import('@stories/Widgets/AddSiteAddress/AddSiteAddress'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AnchorPoint',
        import: () => import('@stories/Widgets/AnchorPoint/AnchorPoint'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ArticleListing',
        import: () => import('@stories/Widgets/ArticleListing/ArticleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleDetailsFooter',
        import: () => import('@stories/Widgets/ArticleDetailsFooter/ArticleDetailsFooter'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleDetailsHeader',
        import: () => import('@stories/Widgets/ArticleDetailsHeader/ArticleDetailsHeader'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BranchDetails',
        import: () => import('@stories/Widgets/BranchDetails/BranchDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BranchLocator',
        import: () => import('@stories/Widgets/BranchLocator/BranchLocator'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import('@stories/Widgets/Global/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CartSummary',
        import: () => import('@stories/Widgets/CartSummary/CartSummary'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Checkout',
        import: () => import('@stories/Widgets/Checkout/Checkout'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CheckoutSummary',
        import: () => import('@stories/Widgets/CheckoutSummary/CheckoutSummary'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CookieDeclaration',
        import: () => import('@stories/Widgets/CookieDeclaration/CookieDeclaration'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CtaBanner',
        import: () => import('@stories/Widgets/CtaBanner/CtaBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DocumentDownloads',
        import: () => import('@stories/Widgets/DocumentDownloads/DocumentDownloads'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedForm',
        import: () => import('@stories/Widgets/Forms/EmbeddedForm/EmbeddedForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedTechnicalDocuments',
        import: () => import('@stories/Widgets/FeaturedTechnicalDocuments/FeaturedTechnicalDocuments'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ForgottenPassword',
        import: () => import('@stories/Widgets/ConfirmationFields/ForgottenPassword/ForgottenPassword'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HireSaleSelector',
        import: () => import('@stories/Widgets/HireSaleSelector/HireSaleSelector'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroBannerCarousel',
        import: () => import('@stories/Widgets/HeroBannerCarousel/HeroBannerCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HtmlSiteMap',
        import: () => import('@stories/Widgets/HtmlSiteMap/HtmlSiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageCtaCards',
        import: () => import('@stories/Widgets/ImageCtaCards/ImageCtaCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCards',
        import: () => import('@stories/Widgets/InformationCards/InformationCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageBanner',
        import: () => import('@stories/Widgets/InnerPageBanner/InnerPageBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'KenticoForm',
        import: () => import('@stories/Widgets/Forms/KenticoForm/KenticoForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'KeyFactsAndBenefits',
        import: () => import('@stories/Widgets/KeyFactsAndBenefits/KeyFactsAndBenefits'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestNews',
        import: () => import('@stories/Widgets/LatestNews/LatestNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LockedContentOverlay',
        import: () => import('@stories/Widgets/LockedContentOverlay/LockedContentOverlay'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoAccreditationList',
        import: () => import('@stories/Widgets/LogoAccreditationList/LogoAccreditationList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaEmbed',
        import: () => import('@stories/Widgets/MediaEmbed/MediaEmbed'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MyHires',
        import: () => import('@stories/Widgets/MyHires/MyHires'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MyProfile',
        import: () => import('@stories/Widgets/MyProfile/MyProfile'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OrderDetail',
        import: () => import('@stories/Widgets/OrderDetail/OrderDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductCardOverview',
        import: () => import('@stories/Widgets/ProductCardOverview/ProductCardOverview'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductCombinations',
        import: () => import('@stories/Widgets/ProductCombinations/ProductCombinations'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductDetails',
        import: () => import('@stories/Widgets/ProductDetails/ProductDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import('@stories/Widgets/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PromotionBanner',
        import: () => import('@stories/Widgets/PromotionBanner/PromotionBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedProducts',
        import: () => import('@stories/Widgets/RelatedProducts/RelatedProducts'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ResetPassword',
        import: () => import('@stories/Widgets/ConfirmationFields/ResetPassword/ResetPassword'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchListing',
        import: () => import('@stories/Widgets/SearchListing/SearchListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SideTabbedContent',
        import: () => import('@stories/Widgets/SideTabbedContent/SideTabbedContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TechnicalDocumentLibraryListing',
        import: () => import('@stories/Widgets/TechnicalDocumentLibraryListing/TechnicalDocumentLibraryListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Testimonials',
        import: () => import('@stories/Widgets/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TrainingListing',
        import: () => import('@stories/Widgets/TrainingListing/TrainingListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UpdateAccountFields',
        import: () => import('@stories/Widgets/UpdateAccountFields/UpdateAccountFields'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UpdatePassword',
        import: () => import('@stories/Widgets/ConfirmationFields/UpdatePassword/UpdatePassword'),
        config: {
            hydrate: 'in-view',
        },
    },
];
